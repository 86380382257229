import React, { useState, useEffect } from "react";
import { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import SplashScreen from "./Components/SplashScreen/SplashScreen";
import "./App.css";

const StartPage = React.lazy(() => import("./Components/StartPage/StartPage"));
const Login = React.lazy(() => import("./Components/Login/Login"));
const Otp = React.lazy(() => import("./Components/Otp/Otp"));
const Upload = React.lazy(() => import("./Components/Upload/Upload"));
const Success = React.lazy(() => import("./Components/Success/Success"));
const Now = React.lazy(() => import("./Components/Now/Now"));
const Face = React.lazy(() => import("./Components/Face/Face"));
const Web = React.lazy(() => import("./Components/Web/Web"));
const QR = React.lazy(() => import("./Components/QR/QR"));
const Invite = React.lazy(() => import("./Components/Invite/Invite"));
const VerifyEmail = React.lazy(() =>
  import("./Components/VerifyEmail/VerifyEmail")
);
const RedirectToNotion = () => {
  useEffect(() => {
    window.location.href =
      "https://desert-olivine-d7e.notion.site/1923cb3b322380b587bfc655e5049d08";
  }, []);

  return null;
};
const AnalysisSummary = () => {
  useEffect(() => {
    window.location.href =
      "https://desert-olivine-d7e.notion.site/1913cb3b322380a2a28bcfe58ddb435d";
  }, []);

  return null;
};

function App() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    localStorage.clear();
    if (location.search.length > 0) {
      const params = location.search.slice(1).split("&");
      const properties = ["provider", "channel", "subchannel"];
      params.forEach((item) => {
        const arr = item.split("=");
        const name = arr[0];
        if (properties.includes(name)) {
          localStorage.setItem(name, arr[1]);
        }
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className="App">
      {loading ? (
        <SplashScreen />
      ) : (
        <Suspense fallback={<SplashScreen />}>
          <Routes>
            <Route path="/" element={<StartPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/otp" element={<Otp />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/success" element={<Success />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/now/*" element={<Now />} />
            <Route path="/face/*" element={<Face />} />
            <Route path="/web/*" element={<Web />} />
            <Route path="/qr/*" element={<QR />} />
            <Route path="/invite/*" element={<Invite />} />
            <Route path="/analysis-results" element={<RedirectToNotion />} />
            <Route path="/analysis-summary" element={<AnalysisSummary />} />
          </Routes>
        </Suspense>
      )}
    </div>
  );
}

export default App;
